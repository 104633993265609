import { gql } from '@apollo/client';

export const CREATE_CONFIGURATION = gql`
  mutation Mutation($input: CreateWorkspaceConfigInput!) {
    createWorkspaceConfig(input: $input) {
      message
    }
  }
`;

export const UPDATE_CONFIGURATION = gql`
  mutation UpdateWorkspaceConfig($input: UpdateWorkspaceConfigInput!) {
    updateWorkspaceConfig(input: $input) {
      message
    }
  }
`;

export const DELETE_CONFIGURATION = gql`
  mutation DeleteWorkspaceConfig($workspaceConfigId: ID!) {
    deleteWorkspaceConfig(workspaceConfigId: $workspaceConfigId) {
      message
    }
  }
`;
