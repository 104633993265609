import {
  DeleteOutlined,
  EditOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Card, Col, Modal, Row } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../AppContext';
import { ROUTES } from '../../common/constants';
import LoaderComponent from '../../components/LoaderComponent';
import NoDataFound from '../../components/NoDataFound';
import useRouter from '../../hooks/useRouter';
import { DELETE_TEAM } from './graphql/Mutations';
import { LIST_TEAMS } from './graphql/Queries';
import './team.less';

const Teams = () => {
  const {
    state: { workspace },
  } = useContext(AppContext);
  const { navigate } = useRouter();

  const [deleteTeam, setDeleteTeam] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [listTeams, { data: teamsData }] = useLazyQuery(LIST_TEAMS, {
    fetchPolicy: 'network-only',
  });

  const [deleteMutate, { loading: deleteLoading }] = useMutation(DELETE_TEAM);

  const getTeams = () => {
    listTeams({
      variables: {
        where: {
          workspaceId: workspace?.id,
        },
        filter: {
          skip: 0,
          limit: 999,
        },
      },
      onCompleted: () => setIsLoading(false),
      onError: () => setIsLoading(false),
    });
  };

  const handleDelete = () => {
    deleteMutate({
      variables: { where: { id: deleteTeam } },
      onCompleted: () => {
        setDeleteTeam(null);
        getTeams();
      },
      onError() {},
    });
  };

  useEffect(() => {
    setTimeout(() => {
      if (workspace?.id) {
        getTeams();
      }
    }, 500);
  }, [workspace]);

  return (
    <>
      <div className="team-container">
        <div className="team-header">
          <h2>Teams</h2>
          <Button
            type="primary"
            onClick={() => navigate(`${ROUTES?.TEAMS}${ROUTES?.CREATE_TEAM}`)}
          >
            Create Team
          </Button>
        </div>
        {isLoading ? (
          <LoaderComponent />
        ) : (
          <Row gutter={[24, 24]}>
            {teamsData?.listFigmaTeams?.teams?.length > 0 ? (
              teamsData?.listFigmaTeams?.teams?.map((team) => (
                <Col xs={12} md={8} lg={6} span={6} key={team?.id}>
                  <Card
                    actions={[
                      <EditOutlined
                        key="edit"
                        onClick={() =>
                          navigate(
                            `${ROUTES?.TEAMS}${ROUTES?.UPDATE_TEAM}/${team?.id}`,
                          )
                        }
                      />,
                      <DeleteOutlined
                        key="delete"
                        onClick={() => setDeleteTeam(team?.id)}
                      />,
                    ]}
                  >
                    <Card.Meta
                      title={
                        <div className="d-flex justify-between mb-16">
                          <h4 className="m-0">{team?.name}</h4>
                          <SettingOutlined
                            key="setting"
                            onClick={() =>
                              navigate(
                                `${ROUTES?.CONFIG}/${team?.id}/${team?.name}`,
                              )
                            }
                          />
                        </div>
                      }
                      description={team?.teamId}
                    />
                  </Card>
                </Col>
              ))
            ) : (
              <NoDataFound message="No Teams Created" />
            )}
          </Row>
        )}
        <Modal
          title="Delete the Team"
          okText="Yes"
          cancelText="No"
          open={!!deleteTeam}
          onOk={handleDelete}
          okButtonProps={{ loading: deleteLoading }}
          onCancel={() => {
            setDeleteTeam(null);
          }}
        >
          Are you sure you want to delete this team?
        </Modal>
      </div>
    </>
  );
};

export default Teams;
