/* ROUTERS  */
export const ROUTES = {
  MAIN: '/',
  LOGOUT: '/logout',
  SIGNUP: '/signup',
  LOGIN: '/login',
  VERIFY_USER: '/verify-user',
  CREATE_USER: '/create-user',
  ONBOARDING: '/onboarding',
  FIGMA_CONNECT: '/figma-connect',
  CLICK_UP_CONNECT: '/clickup-connect',
  TEAMS: '/teams',
  CREATE_TEAM: '/create-team',
  UPDATE_TEAM: '/update-team',
  CONFIG: '/configuration',
  CREATE_CONFIG: '/create-config',
  UPDATE_CONFIG: '/update-config',
  MEMBERS: '/members',
  INVITE_MEMBERS: '/invite-members',
  AUTHENTICATION: '/authentication',
  TABLE: '/tables',
  PROFILE: '/profile',
  PRIVACY_POLICY: '/privacy-policy',
  TERMS_AND_CONDITIONS: '/terms-and-conditions',
};

/*  Modules */
export const MODULES = {
  DASHBOARD: 'Dashboard',
};

/* Authentication */
export const TOKEN = 'TOKEN';
export const WORKSPACE_KEY = 'WORKSPACE_KEY';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const USER = 'USER';
export const CURRENT_EMAIL = 'CURRENT_EMAIL';
export const IS_NEW_USER = 'IS_NEW_USER';
export const REDIRECT_WAITING = 'REDIRECT_WAITING';

/* OTP */
export const OTP_SIZE = 6;
export const OTP_ATTEMPT = 3;

export const ROLES = {
  SUPER_ADMIN: 'Super admin',
  ADMIN: 'Admin',
};

export const ROLE_KEYS = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  ADMIN: 'ADMIN',
};
export const FIGMA = 'FIGMA';

/* Date and time */
export const defaultDateFormat = 'MM/DD/YYYY';

export const REGEX = {
  NAME: /^[a-z ,.'-]+$/i,
  ZIPCODE: /^[0-9]{5,6}$/,
  CITY: /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/,
  WEB_URL: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
  PASSWORD: /^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*]{8,16}$/,
  PHONE: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
  EMAIL: /^(([^<>()[\]\\.,;:!#$*%^'`~={}?/&\s@"]+(\.[^<>()[\]\\.,;:!#$*%^'`~={}?/&\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  AMOUNT: /^\d+$|^\d+\.\d*$/,
  OPTIONALNEGATIVEAMOUNT: /^[-]?\d+$|^[-]?\d+\.\d*$/,
  NUMBER: /^\d+$/,
  COUNTRY_CODE_PHONE: /^[0-9]{7,12}$/,
};

export const LIMIT = 10;
export const SCROLL_PERCENT = 0.85;

export const ORDER = [
  { name: 'Ascending', value: 'ASC' },
  { name: 'Descending', value: 'DESC' },
];

export const GENDER = [
  { name: 'Female', value: 'FEMALE' },
  { name: 'Male', value: 'MALE' },
  { name: 'Other', value: 'OTHER' },
];
export const ERROR_PAGE_TITLE = 'Oops! An error occurred!';
export const ERROR_PAGE_SUBTITLE =
  'Something is broken. Please let us know what you were doing when this error occurred. We will fix it as soon as possible. Sorry for any inconvenience caused.';

export const GUTTER_VARIATIONS = { xs: 16, sm: 16, md: 24, lg: 32 };

export const BREAKPOINTS = {
  mobile: 550,
  tablet: 767,
  desktop: 1000,
  hd: 1200,
};

export const ALL_IMAGE_UPLOAD = ['jpeg', 'jpg', 'png'];

export const AVATAR_COLOR = [
  '#173F5F',
  '#1F639C',
  '#4DA480',
  '#CB543F',
  '#7046A6',
  '#AB567F',
  '#667C89',
  '#49A8EE',
  '#D63964',
];
