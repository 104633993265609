import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import CreateTeam from './createTeam';
import Teams from './teams';

const TeamsWrapper = () => (
  <>
    <Routes>
      <Route path={ROUTES?.MAIN} exact element={<Teams />} />
      <Route
        path={`${ROUTES?.MAIN}${ROUTES?.CREATE_TEAM}`}
        exact
        element={<CreateTeam />}
      />
      <Route
        path={`${ROUTES?.MAIN}${ROUTES?.UPDATE_TEAM}/:id`}
        exact
        element={<CreateTeam />}
      />
    </Routes>
  </>
);
export default TeamsWrapper;
