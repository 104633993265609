import { useMutation } from '@apollo/client';
import { Form, Input, Modal } from 'antd';
import React, { useContext } from 'react';
import { AppContext } from '../../../AppContext';
import { formValidatorRules } from '../../../common/utils';
import { INVITE_MEMBER } from '../graphql/Mutations';

const { required, email } = formValidatorRules;

const initialValues = { email: '' };

const InviteMemberForm = ({ inviteModal, onClose, fetchMemberList }) => {
  const {
    state: { workspace },
  } = useContext(AppContext);
  const [form] = Form.useForm();

  const [inviteMember, { loading }] = useMutation(INVITE_MEMBER);

  const handleClose = () => {
    form.setFieldsValue(initialValues);
    onClose();
    fetchMemberList();
  };

  const onFinish = async (values) => {
    await inviteMember({
      variables: {
        data: [
          {
            ...values,
          },
        ],
        workspaceId: workspace?.id,
      },
      onCompleted: () => handleClose(),
    });
  };

  return (
    <>
      <Modal
        title="Invite Member"
        okText="Send"
        cancelText="Cancel"
        open={inviteModal}
        onOk={() => form.submit()}
        okButtonProps={{ loading }}
        onCancel={handleClose}
      >
        <Form
          name="invite-members"
          className="invite-members-form"
          layout="vertical"
          form={form}
          onFinish={onFinish}
          initialValues={initialValues}
        >
          <Form.Item
            name="email"
            label="Email"
            rules={[{ required, message: 'Please enter email!' }, email]}
          >
            <Input placeholder="Enter Email" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default InviteMemberForm;
