import React from 'react';
import './publicPage.less';

const TermsAndConditions = () => (
  <div className="public-pages-container">
    <h1 className="mt-0">Terms and Conditions</h1>
    <div>
      <h4>1. Introduction</h4>
      <div>
        <p>
          These website’s standard terms and conditions written on this webpage
          shall manage for your use of our website, FlickUp accessible at
          https://flickup.logicwind.co/.
        </p>
        <p>
          These terms will be applied fully and affect to your use of this
          website. By using this website, you agreed to accept all terms and
          conditions written here. You must not use this Website if you disagree
          with any of these Website Standard Terms and Conditions.
        </p>
      </div>
    </div>
    <div>
      <h4>2. Intellectual Property Rights</h4>
      <div>
        <p>
          Other than the content you own, under these Terms, FlickUp and/or its
          licensors own all the intellectual property rights and materials
          contained in this Website.
        </p>
        <p>
          You are granted a limited license only for purposes of viewing the
          material contained on this Website.
        </p>
      </div>
    </div>
    <div>
      <h4>3. Restrictions</h4>
      <div>
        <p>You are specifically restricted from all of the following:</p>
        <ul className="list">
          <li>publishing any Website material in any other media; </li>
          <li>
            selling, sublicensing and/or otherwise commercializing any Website
            material;{' '}
          </li>
          <li>publicly performing and/or showing any Website material;</li>
          <li>
            using this Website in any way that is or may be damaging to this
            Website;
          </li>
          <li>
            using this Website in any way that impacts user access to this
            Website;
          </li>
          <li>
            using this Website contrary to applicable laws and regulations, or
            in any way may cause harm to the Website, or to any person or
            business entity;
          </li>
          <li>
            engaging in any data mining, data harvesting, data extracting or any
            other similar activity in relation to this Website;
          </li>
        </ul>
        <p>
          Certain areas of this Website are restricted from being access by you
          and FlickUp may further restrict access by you to any areas of this
          Website, at any time, in absolute discretion. Any user ID and password
          you may have for this Website are confidential and you must maintain
          confidentiality as well.
        </p>
      </div>
    </div>
    <div>
      <h4>4. Your Content</h4>
      <div>
        <p>
          In these Website Standard Terms and Conditions, "Your Content" shall
          mean any audio, video text, images or other material you choose to
          display on this Website. By displaying Your Content, you grant FlickUp
          a non-exclusive, worldwide irrevocable, sub licensable license to use,
          reproduce, adapt, publish, translate and distribute it in any and all
          media.
        </p>
        <p>
          Your Content must be your own and must not be invading any
          third-party's rights. FlickUp reserves the right to remove any of Your
          Content from this Website at any time without notice.
        </p>
      </div>
    </div>
    <div>
      <h4>5. No warranties</h4>
      <div>
        <p>
          This Website is provided "as is," with all faults, and FlickUp express
          no representations or warranties, of any kind related to this Website
          or the materials contained on this Website. Also, nothing contained on
          this Website shall be interpreted as advising you.
        </p>
      </div>
    </div>
    <div>
      <h4>6. Limitation of liability</h4>
      <div>
        <p>
          In no event shall FlickUp, nor any of its officers, directors and
          employees, shall be held liable for anything arising out of or in any
          way connected with your use of this Website whether such liability is
          under contract. FlickUp, including its officers, directors and
          employees shall not be held liable for any indirect, consequential or
          special liability arising out of or in any way related to your use of
          this Website.
        </p>
      </div>
    </div>
    <div>
      <h4>7. Indemnification</h4>
      <div>
        <p>
          You hereby indemnify to the fullest extent FlickUp from and against
          any and/or all liabilities, costs, demands, causes of action, damages
          and expenses arising in any way related to your breach of any of the
          provisions of these Terms.
        </p>
      </div>
    </div>
    <div>
      <h4>8. Severability</h4>
      <div>
        <p>
          If any provision of these Terms is found to be invalid under any
          applicable law, such provisions shall be deleted without affecting the
          remaining provisions herein.
        </p>
      </div>
    </div>
    <div>
      <h4>9. Variation of Terms</h4>
      <div>
        <p>
          FlickUp is permitted to revise these Terms at any time as it sees fit,
          and by using this Website you are expected to review these Terms on a
          regular basis.
        </p>
      </div>
    </div>
    <div>
      <h4>10. Assignment</h4>
      <div>
        <p>
          The FlickUp is allowed to assign, transfer, and subcontract its rights
          and/or obligations under these Terms without any notification.
          However, you are not allowed to assign, transfer, or subcontract any
          of your rights and/or obligations under these Terms
        </p>
      </div>
    </div>
    <div>
      <h4>11. Entire Agreement</h4>
      <div>
        <p>
          These Terms constitute the entire agreement between FlickUp and you in
          relation to your use of this Website, and supersede all prior
          agreements and understandings.
        </p>
      </div>
    </div>
    <div>
      <h4>12. Governing Law &amp; Jurisdiction</h4>
      <div>
        <p>
          These Terms will be governed by and interpreted in accordance with the
          laws of the State of in, and you submit to the non-exclusive
          jurisdiction of the state and federal courts located in in for the
          resolution of any disputes.
        </p>
      </div>
    </div>
  </div>
);

export default TermsAndConditions;
