import { Layout } from 'antd';
import React from 'react';
import { Outlet } from 'react-router-dom';
import AppHeader from '../components/header/AppHeader';

const { Content, Footer } = Layout;
const AppLayout = ({ isUser = false }) => (
  <Layout hasSider>
    <Layout className="site-layout">
      <AppHeader isUser={isUser} />
      <Content className="wrapper">
        <Outlet />
      </Content>
      <Footer>
        <div className="text-center">
          Logicwind © {new Date().getFullYear()}
        </div>
      </Footer>
    </Layout>
  </Layout>
);
export default AppLayout;
