import { gql } from '@apollo/client';

export const GET_FIGMA_REDIRECT_URL = gql`
  query GetFigmaRedirectionUrl {
    getFigmaRedirectionUrl {
      url
    }
  }
`;

export const GET_CLICK_UP_REDIRECT_URL = gql`
  query GetClickupRedirectUrl {
    getClickupRedirectUrl {
      url
    }
  }
`;
