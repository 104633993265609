import { gql } from '@apollo/client';

export const ADD_FIGMA_ACCOUNT = gql`
  mutation AddFigmaAccount($data: AddFigmaAccountInput!) {
    addFigmaAccount(data: $data) {
      message
    }
  }
`;

export const ADD_CLICK_UP_ACCOUNT = gql`
  mutation AddClickupAccount($code: String!) {
    addClickupAccount(code: $code) {
      token
    }
  }
`;
