import { useMutation } from '@apollo/client';
import { Button, Form, Input } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../AppContext';
import { ROUTES } from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import LoaderComponent from '../../components/LoaderComponent';
import useRouter from '../../hooks/useRouter';
import AuthWrapper from './AuthWrapper';
import { UPDATE_USER } from './graphql/Mutations';

const { required } = formValidatorRules;

const CreateUser = () => {
  const { getCurrentUser } = useContext(AppContext);
  const { navigate } = useRouter();
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(true);

  const [userMutate, { loading: saveLoading }] = useMutation(UPDATE_USER, {
    onError() {},
  });

  useEffect(() => {
    if (getCurrentUser()?.firstName) {
      navigate(ROUTES?.MAIN);
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, []);

  const onFinish = async (values) => {
    const { firstName, lastName } = values;
    userMutate({
      variables: {
        data: {
          firstName,
          lastName,
        },
      },
      onCompleted: () => navigate(ROUTES?.MAIN),
    });
  };

  if (loading) {
    return <LoaderComponent />;
  }

  return (
    <>
      <AuthWrapper title="Enter Your Name" subTitle="" isLoading={false}>
        <Form
          name="Create-user"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          size="large"
          form={form}
        >
          <Form.Item
            name="firstName"
            rules={[{ required, message: 'Please enter first name!' }]}
          >
            <Input placeholder="Enter first name" />
          </Form.Item>
          <Form.Item
            name="lastName"
            rules={[{ required, message: 'Please enter last name!' }]}
          >
            <Input placeholder="Enter last name" />
          </Form.Item>
          <Form.Item className=" full-width mb-8">
            <Button
              type="primary"
              className="full-width"
              htmlType="submit"
              loading={saveLoading}
              disabled={saveLoading}
            >
              Done
            </Button>
          </Form.Item>
        </Form>
      </AuthWrapper>
    </>
  );
};

export default CreateUser;
