import { useQuery } from '@apollo/client';
import React, { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppContext } from '../AppContext';
import { ROUTES } from '../common/constants';
import LoaderComponent from '../components/LoaderComponent';
import {
  GET_CURRENT_USER,
  GET_USER_WORKSPACE,
} from '../modules/auth/graphql/Queries';
import AppLayout from './layout/AppLayout';

const App = () => {
  const {
    dispatch,
    state: { loading },
  } = useContext(AppContext);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useQuery(GET_CURRENT_USER, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      dispatch({
        type: 'SET_CURRENT_USER',
        data: res?.getUser,
      });
      dispatch({
        type: 'SET_LOADING',
        data: false,
      });

      if (pathname === '/') {
        if (res?.getUser?.accountConfigs?.length === 1) {
          navigate(`${ROUTES?.ONBOARDING}${ROUTES?.CLICK_UP_CONNECT}`);
        } else if (!res?.getUser?.accountConfigs?.length > 0) {
          navigate(`${ROUTES?.ONBOARDING}${ROUTES?.FIGMA_CONNECT}`);
        }
      }
    },
    onError() {
      dispatch({
        type: 'SET_LOADING',
        data: false,
      });
    },
  });

  useQuery(GET_USER_WORKSPACE, {
    fetchPolicy: 'network-only',
    variables: {
      filters: {
        limit: 10,
        skip: 0,
      },
    },
    onCompleted: (res) => {
      dispatch({ type: 'SET_WORKSPACE', data: res?.getWorkspace?.[0] ?? null });
    },
  });

  if (loading) return <LoaderComponent />;
  return <AppLayout isUser />;
};

export default App;
