import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_MEMBERS_LIST = gql`
  query GetWorkspaceMemberList($filters: Filters!, $workspaceId: ID!) {
    getWorkspaceMemberList(filters: $filters, workspaceId: $workspaceId) {
      workspaceId
      userRegisterStatus
      role
      invitedUserEmail
    }
  }
`;
