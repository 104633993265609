import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import Members from './members';
import './members.less';

const MembersWrapper = () => (
  <>
    <Routes>
      <Route path={`${ROUTES?.MAIN}`} exact element={<Members />} />
    </Routes>
  </>
);

export default MembersWrapper;
