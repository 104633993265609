import React, { createContext, useReducer } from 'react';
import client from './apollo';
import {
  CURRENT_EMAIL,
  REFRESH_TOKEN,
  TOKEN,
  USER,
  WORKSPACE_KEY,
} from './common/constants';
import { getItem, setItem } from './common/utils';

const getLoggedInUser = () => {
  let loggedInUser = getItem(USER);
  loggedInUser = loggedInUser ? JSON?.parse(loggedInUser) : null;
  return loggedInUser;
};

const initialState = {
  currentUser: null,
  authToken: getItem(TOKEN),
  workspace: getItem(WORKSPACE_KEY),
  userEmail: getItem(CURRENT_EMAIL),
  loading: true,
};

const reducer = (state, action) => {
  switch (action?.type) {
    case 'SET_CURRENT_USER':
      // eslint-disable-next-line no-case-declarations
      const user = action?.data || {};
      setItem(
        USER,
        user && Object?.keys(user)?.length ? JSON?.stringify(user) : null,
      );
      return { ...state, currentUser: { ...user } };
    case 'LOGOUT':
      // eslint-disable-next-line no-undef
      localStorage?.clear();
      client?.clearStore();
      return {
        ...initialState,
        authenticated: false,
        authToken: null,
        user: {},
      };
    case 'SET_AUTHENTICATED':
      return { ...state, authenticated: action?.data };
    case 'SET_TOKEN':
      setItem(TOKEN, action?.data);
      return { ...state, authToken: action?.data };
    case 'SET_REFRESH_TOKEN':
      setItem(REFRESH_TOKEN, action?.data);
      return {
        ...state,
        refreshToken: action?.data,
      };
    case 'SET_WORKSPACE':
      setItem(
        WORKSPACE_KEY,
        action?.data ? JSON.stringify(action?.data) : null,
      );
      return { ...state, workspace: action?.data };
    case 'SET_EMAIL':
      return { ...state, userEmail: action?.data };
    case 'SET_PROFILE_LOGO':
      return { ...state, profileLogo: action?.data };
    case 'SET_LOADING':
      return { ...state, loading: action?.data };
    default:
      return { ...state };
  }
};

const AppContext = createContext({
  state: initialState,
  dispatch: () => {},
});

function AppContextProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const getToken = () => getItem(TOKEN) || null;
  const getRefreshToken = () => getItem(REFRESH_TOKEN);
  const getCurrentUser = () =>
    getItem(USER) ? JSON?.parse(getItem(USER)) : {};
  const getCurrentUserRole = () => {
    const loggedInUser = getLoggedInUser();
    return loggedInUser?.roles?.[0] || '';
  };

  const isAuthenticated = () => state?.authenticated;

  const initializeAuth = (authToken, userData, refreshToken) => {
    const token = authToken || getToken();
    const user = userData || getCurrentUser();
    const refresh = refreshToken || getRefreshToken();
    if (token) {
      dispatch({ type: 'SET_TOKEN', data: token });
      dispatch({ type: 'SET_REFRESH_TOKEN', data: refresh });
      dispatch({ type: 'SET_AUTHENTICATED', data: true });
      dispatch({ type: 'SET_CURRENT_USER', data: user });
    }
  };

  const value = {
    state,
    dispatch,
    isAuthenticated,
    getToken,
    getRefreshToken,
    initializeAuth,
    getCurrentUserRole,
    getCurrentUser,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

const AppContextConsumer = AppContext?.Consumer;

export { AppContext, AppContextConsumer, AppContextProvider };
