import { DeleteOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Button, Card, Col, Modal, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ReactComponent as Clickup } from '../../assets/svg/clickup.svg';
import { ReactComponent as Figma } from '../../assets/svg/figma.svg';
import { ROUTES } from '../../common/constants';
import LoaderComponent from '../../components/LoaderComponent';
import NoDataFound from '../../components/NoDataFound';
import useRouter from '../../hooks/useRouter';
import { DELETE_CONFIGURATION } from './graphql/Mutations';
import {
  GET_FIGMA_PROJECT_LIST,
  GET_WORKSPACE_CONFIG_LIST,
} from './graphql/Queries';

const Configurations = () => {
  const { id, team } = useParams();
  const { navigate } = useRouter();

  const [deleteConfiguration, setDeleteConfiguration] = useState(null);

  const [deleteMutate, { loading: deleteLoading }] = useMutation(
    DELETE_CONFIGURATION,
    {
      onError() {},
    },
  );

  const [configList, { data: configListData, loading }] = useLazyQuery(
    GET_WORKSPACE_CONFIG_LIST,
    {
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  const { data: projectListData } = useQuery(GET_FIGMA_PROJECT_LIST, {
    fetchPolicy: 'network-only',
    variables: {
      figmaProjectId: id,
    },
    onError() {},
  });

  const fetchConfigList = () => {
    configList({
      variables: {
        figmaProjectId: id,
        filters: {
          limit: 10,
          skip: 0,
        },
      },
    });
  };

  useEffect(() => {
    fetchConfigList();
  }, []);

  const handleDelete = () => {
    deleteMutate({
      variables: { workspaceConfigId: deleteConfiguration },
      onCompleted: () => {
        setDeleteConfiguration(null);
        fetchConfigList();
      },
    });
  };

  return (
    <div className="config-wrapper">
      <div className="align-center d-flex justify-between">
        <h2>Configurations {`( ${team} )`}</h2>
        <Button
          type="primary"
          onClick={() =>
            navigate(`${ROUTES?.CONFIG}/${id}${ROUTES?.CREATE_CONFIG}/${team}`)
          }
        >
          Create Configuration
        </Button>
      </div>
      {loading ? (
        <LoaderComponent />
      ) : (
        <Row gutter={[24, 24]}>
          {configListData?.getWorkspaceConfigList?.length > 0 ? (
            configListData?.getWorkspaceConfigList?.map((config) => (
              <Col xs={12} md={8} lg={6} span={6} key={config?.id}>
                <Card
                  actions={[
                    <DeleteOutlined
                      key="delete"
                      onClick={() => setDeleteConfiguration(config?.id)}
                    />,
                  ]}
                >
                  <div>
                    <h3 className="m-0">
                      {config?.clickupConfig?.space?.name}
                    </h3>
                    <div className="d-flex align-center mt-8">
                      <div className="config-icon-block">
                        <Figma />
                      </div>
                      <p className="mt-8 mb-8">
                        {config?.figmaConfig?.figmaProjectIds?.map(
                          (item, i) => {
                            const project = projectListData?.getFigmaProjects?.find(
                              (ele) => ele?.id === item,
                            );
                            return `${project?.name ?? '-'}${
                              i <
                              config?.figmaConfig?.figmaProjectIds?.length - 1
                                ? ', '
                                : ''
                            }`;
                          },
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex align-center mt-8">
                    <div className="config-icon-block">
                      <Clickup />
                    </div>
                    <span>
                      <b>{config?.clickupConfig?.space?.name}</b> {' > '}
                      {config?.clickupConfig?.folder?.name}
                      {' > '}
                      {config?.clickupConfig?.list?.name}
                    </span>
                  </div>
                </Card>
              </Col>
            ))
          ) : (
            <NoDataFound message="No Configuration Created" />
          )}
        </Row>
      )}
      <Modal
        title="Delete the Configuration"
        okText="Yes"
        cancelText="No"
        open={!!deleteConfiguration}
        onOk={handleDelete}
        okButtonProps={{ loading: deleteLoading }}
        onCancel={() => {
          setDeleteConfiguration(null);
        }}
      >
        Are you sure you want to delete this configuration?
      </Modal>
    </div>
  );
};

export default Configurations;
