import { gql } from '@apollo/client';

export const LOGOUT_USER = gql`
  mutation logout {
    logout {
      message
    }
  }
`;

export const REFRESH_TOKEN = gql`
  query refreshToken($data: RefreshTokenInput!) {
    refreshToken(data: $data) {
      token
      user {
        id
        email
        profileImage
        name
        firstName
        lastName
      }
    }
  }
`;

export const GET_CURRENT_USER = gql`
  query GetUser {
    getUser {
      email
      firstName
      id
      isActive
      lastName
      profileImage
      role
      accountConfigs {
        metaData
        refreshToken
        token
        type
        expiry
      }
    }
  }
`;

export const GET_PROFILE_SIGNED_URL = gql`
  query GetProfileImageUploadSignedUrl(
    $data: GetProfileImageUploadSignedUrlInput!
  ) {
    getProfileImageUploadSignedUrl(data: $data) {
      key
      signedUrl
    }
  }
`;

export const GET_USER_WORKSPACE = gql`
  query GetWorkspace($filters: Filters!) {
    getWorkspace(filters: $filters) {
      id
      name
      logo
    }
  }
`;
