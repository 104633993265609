import { Empty } from 'antd';
import React from 'react';

const NoDataFound = ({ message = 'No Data available' }) => (
  <>
    <div className="full-width m-48">
      <Empty className="m-auto" description="">
        <h3>{message}</h3>
      </Empty>
    </div>
  </>
);

export default NoDataFound;
