import React from 'react';

const PrivacyPolicy = () => (
  <div className="public-pages-container">
    <h1 className="mt-0">Privacy Policy</h1>
    <div>
      <p>
        At FlickUp, accessible from https://flickup.logicwind.co/, one of our
        main priorities is the privacy of our visitors. This Privacy Policy
        document contains types of information that is collected and recorded by
        FlickUp and how we use it.FlickUp
      </p>
      <p>
        If you have additional questions or require more information about our
        Privacy Policy, do not hesitate to contact us.FlickUp
      </p>
    </div>
    <div>
      <div>
        <h4>1. Log Files</h4>
        <div>
          <p>
            FlickUp follows a standard procedure of using log files. These files
            log visitors when they visit websites. All hosting companies do this
            and a part of hosting services' analytics. The information collected
            by log files include internet protocol (IP) addresses, browser type,
            Internet Service Provider (ISP), date and time stamp, referring/exit
            pages, and possibly the number of clicks. These are not linked to
            any information that is personally identifiable. The purpose of the
            information is for analyzing trends, administering the site,
            tracking users' movement on the website, and gathering demographic
            information.
          </p>
        </div>
      </div>
      <div>
        <h4>2. Cookies and Web Beacons</h4>
        <div>
          <p>
            Like any other website, FlickUp uses "cookies". These cookies are
            used to store information including visitors' preferences, and the
            pages on the website that the visitor accessed or visited. The
            information is used to optimize the users' experience by customizing
            our web page content based on visitors' browser type and/or other
            information.
          </p>
        </div>
      </div>
      <div>
        <h4>3. Privacy Policies</h4>
        <div>
          <p>
            You may consult this list to find the Privacy Policy for each of the
            advertising partners of FlickUp.
          </p>
          <p>
            Third-party ad servers or ad networks uses technologies like
            cookies, JavaScript, or Web Beacons that are used in their
            respective advertisements and links that appear on FlickUp, which
            are sent directly to users' browser. They automatically receive your
            IP address when this occurs. These technologies are used to measure
            the effectiveness of their advertising campaigns and/or to
            personalize the advertising content that you see on websites that
            you visit.
          </p>
          <p>
            Note that FlickUp has no access to or control over these cookies
            that are used by third-party advertisers.
          </p>
        </div>
      </div>
      <div>
        <h4>4. Third Party Privacy Policies</h4>
        <div>
          <p>
            FlickUp's Privacy Policy does not apply to other advertisers or
            websites. Thus, we are advising you to consult the respective
            Privacy Policies of these third-party ad servers for more detailed
            information. It may include their practices and instructions about
            how to opt-out of certain options.
          </p>
          <p>
            You can choose to disable cookies through your individual browser
            options. To know more detailed information about cookie management
            with specific web browsers, it can be found at the browsers'
            respective websites. What Are Cookies?
          </p>
        </div>
      </div>
      <div>
        <h4>5. Children's Information</h4>
        <div>
          <p>
            Another part of our priority is adding protection for children while
            using the internet. We encourage parents and guardians to observe,
            participate in, and/or monitor and guide their online activity.
          </p>
        </div>
      </div>
      <div>
        <h4>6. Limitation of liability</h4>
        <div>
          <p>
            In no event shall FlickUp, nor any of its officers, directors and
            employees, shall be held liable for anything arising out of or in
            any way connected with your use of this Website whether such
            liability is under contract. FlickUp, including its officers,
            directors and employees shall not be held liable for any indirect,
            consequential or special liability arising out of or in any way
            related to your use of this Website.
          </p>
          <p>
            FlickUp does not knowingly collect any Personal Identifiable
            Information from children under the age of 13. If you think that
            your child provided this kind of information on our website, we
            strongly encourage you to contact us immediately and we will do our
            best efforts to promptly remove such information from our records.
          </p>
        </div>
      </div>
      <div>
        <h4>7. Online Privacy Policy Only</h4>
        <div>
          <p>
            This Privacy Policy applies only to our online activities and is
            valid for visitors to our website with regards to the information
            that they shared and/or collect in FlickUp. This policy is not
            applicable to any information collected offline or via channels
            other than this website.
          </p>
        </div>
      </div>
      <div>
        <h4>8. Consent</h4>
        <div>
          <p>
            By using our website, you hereby consent to our Privacy Policy and
            agree to its Terms and Conditions.
          </p>
        </div>
      </div>
    </div>
  </div>
);
export default PrivacyPolicy;
