import { useMutation, useQuery } from '@apollo/client';
import { Button, Card } from 'antd';
import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ReactComponent as Clickup } from '../../assets/svg/clickup.svg';
import { ROUTES } from '../../common/constants';
import LoaderComponent from '../../components/LoaderComponent';
import { ADD_CLICK_UP_ACCOUNT } from './graphql/Mutations';
import { GET_CLICK_UP_REDIRECT_URL } from './graphql/Queries';

const ConnectClickup = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');

  const { loading, data } = useQuery(GET_CLICK_UP_REDIRECT_URL, {
    fetchPolicy: 'network-only',
    onError: (err) => {
      if (err?.message === 'Account already exist!') {
        navigate(ROUTES.MAIN);
      }
    },
  });

  const [addClickupMutate] = useMutation(ADD_CLICK_UP_ACCOUNT, {
    onError() {},
  });

  const handleLoginClickup = () => {
    // eslint-disable-next-line no-undef
    window.open(data?.getClickupRedirectUrl?.url, '_self');
  };

  useEffect(() => {
    if (code) {
      addClickupMutate({
        variables: { code },
        onCompleted: (res) => {
          if (res?.addClickupAccount) {
            navigate(ROUTES.MAIN);
          }
        },
      });
    }
  }, []);
  return loading ? (
    <LoaderComponent />
  ) : (
    <>
      <Card
        title="Connect With Click Up"
        bordered={false}
        className="connect-card"
      >
        <Button
          className="figma-connect-btn"
          disabled={code}
          onClick={handleLoginClickup}
        >
          <Clickup />
          {data?.getClickupRedirectUrl?.url
            ? 'Connect Account'
            : 'Account Connected'}
        </Button>
      </Card>
    </>
  );
};

export default ConnectClickup;
