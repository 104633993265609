import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Tag,
  Upload,
  message,
} from 'antd';
import axios from 'axios';
import { trim } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../AppContext';
import editIcon from '../../assets/images/edit-icon.png';
import { ReactComponent as Clickup } from '../../assets/svg/clickup.svg';
import { ReactComponent as Figma } from '../../assets/svg/figma.svg';
import { ALL_IMAGE_UPLOAD, FIGMA, REGEX, ROUTES } from '../../common/constants';
import { formValidatorRules, previewImage } from '../../common/utils';
import useRouter from '../../hooks/useRouter';
import './auth.less';
import {
  DELETE_CLICKUP_ACCOUNT,
  DELETE_FIGMA_ACCOUNT,
  UPDATE_USER,
} from './graphql/Mutations';
import { GET_CURRENT_USER, GET_PROFILE_SIGNED_URL } from './graphql/Queries';

const { required } = formValidatorRules;

const icons = { FIGMA: <Figma />, CLICKUP: <Clickup /> };

const ProfileSettings = () => {
  const {
    state: { currentUser },
    dispatch,
  } = useContext(AppContext);

  const [form] = Form?.useForm();
  const { navigate } = useRouter();

  const [currentFile, setCurrentFile] = useState(null);
  const [btnLoading, setBtnLoading] = useState(false);
  const [imageUpload, setImageUpload] = useState([]);
  const [state, setState] = useState({
    selectedImage: {},
    uploading: false,
  });

  /* for future use */
  /*  const { data: workspaceData, loading: workspaceLoading } = useQuery(
    GET_USER_TEAM,
    {
      fetchPolicy: 'network-only',
    },
  ); */

  const [initialValue] = useState({
    firstName: currentUser?.firstName ? trim(currentUser?.firstName) : '',
    lastName: currentUser?.lastName ? trim(currentUser?.lastName) : '',
    email: currentUser?.email,
    // defaultWorkspace: currentUser?.defaultWorkspace, // for future use
  });

  useEffect(() => {
    if (currentUser?.profileImage) {
      setImageUpload([{ url: currentUser?.profileImage }]);
    } else {
      setImageUpload([]);
    }
  }, [currentUser]);

  const [updateUser] = useMutation(UPDATE_USER, {
    onCompleted() {
      setBtnLoading(false);
    },
    onError() {
      setBtnLoading(false);
    },
  });

  const [getUser] = useLazyQuery(GET_CURRENT_USER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) =>
      dispatch({
        type: 'SET_CURRENT_USER',
        data: res?.getUser,
      }),
  });

  const navigateUser = async () => {
    const res = await getUser();
    if (res?.data?.getUser?.accountConfigs?.length === 1) {
      if (res?.data?.getUser?.accountConfigs?.[0]?.type === FIGMA) {
        navigate(`${ROUTES?.ONBOARDING}${ROUTES?.CLICK_UP_CONNECT}`);
      } else {
        navigate(`${ROUTES?.ONBOARDING}${ROUTES?.FIGMA_CONNECT}`);
      }
    } else if (res?.data?.getUser?.accountConfigs?.length === 0) {
      navigate(`${ROUTES?.ONBOARDING}${ROUTES?.FIGMA_CONNECT}`);
    }
  };

  const [deleteFigmaMutate] = useMutation(DELETE_FIGMA_ACCOUNT, {
    onError() {},
    onCompleted() {
      navigateUser();
    },
  });

  const [deleteClickupMutate] = useMutation(DELETE_CLICKUP_ACCOUNT, {
    onError() {},
    onCompleted() {
      navigateUser();
    },
  });

  useEffect(() => {
    getUser();
  }, []);

  const [getSignedURL] = useLazyQuery(GET_PROFILE_SIGNED_URL, {
    onError() {
      setBtnLoading(false);
      setState({ ...state, uploading: false, selectedImage: {} });
    },
  });

  const { selectedImage } = state;

  const handleUpdate = async (value) => {
    const { firstName, lastName } = value;
    setBtnLoading(true);
    // eslint-disable-next-line no-undef
    const formData = new FormData();
    formData?.append('file', selectedImage);
    setState({
      ...state,
      uploading: true,
    });

    if (currentFile) {
      const res = await getSignedURL({
        variables: {
          data: {
            fileName: currentFile?.name,
          },
        },
      });
      try {
        await axios?.put(
          res?.data?.getProfileImageUploadSignedUrl?.signedUrl,
          currentFile,
        );
        const data = {
          firstName,
          lastName,
          profileImage: res?.data?.getProfileImageUploadSignedUrl?.key,
        };
        const userData = await updateUser({
          variables: {
            data,
          },
        });
        if (userData?.data?.updateUser) {
          getUser();
          setBtnLoading(false);
        }
      } catch (err) {
        return err;
      }
    } else {
      const userData = await updateUser({
        variables: {
          data: {
            firstName,
            lastName,
            profileImage: imageUpload?.[0]?.url || null,
          },
        },
      });
      if (userData?.data?.updateUser) {
        getUser();
        setBtnLoading(false);
      }
    }
  };

  const onChangeImageUpload = async (info) => {
    const {
      file: { name = '', url },
    } = info;

    const ext = name?.substring(name?.lastIndexOf('.') + 1);

    if (ALL_IMAGE_UPLOAD?.includes(ext) && !url) {
      setImageUpload([...info?.fileList]);
      setCurrentFile(info?.file);
    } else if (info?.file?.status === 'removed') {
      setCurrentFile(null);
      setImageUpload([]);
    } else {
      message?.destroy();
      message?.error(`${info?.file?.name} file is not image file.`);
    }
  };
  return (
    <div className="profile-card">
      <div className="card-content">
        <Card title="Profile Settings" bordered className="card-title">
          <Form
            name="profile-settings"
            layout="vertical"
            form={form}
            onFinish={handleUpdate}
            initialValues={initialValue}
          >
            <Form.Item name="profileImage">
              <div className="profile-upload">
                <Upload
                  maxCount={1}
                  onChange={onChangeImageUpload}
                  previewFile={previewImage}
                  fileList={imageUpload}
                  beforeUpload={() => false}
                  listType="picture-card"
                  showUploadList={{ showPreviewIcon: false }}
                >
                  {!imageUpload?.length && (
                    <img src={editIcon} alt="icon" className="icon-image" />
                  )}
                </Upload>
                <span className="profile-heading font-12 ml-8">
                  Update Profile Image
                </span>
              </div>
            </Form.Item>
            <Row gutter={10}>
              <Col span={12}>
                <Form.Item
                  name="firstName"
                  label="First Name"
                  rules={[
                    {
                      ...required,
                      whitespace: true,
                      message: 'Enter a valid First Name',
                    },
                    {
                      pattern: REGEX?.NAME,
                      message: 'Enter a valid First Name',
                    },
                  ]}
                >
                  <Input placeholder="Enter First Name" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="lastName"
                  label="Last Name"
                  rules={[
                    {
                      ...required,
                      whitespace: true,
                      message: 'Enter a valid Last Name',
                    },
                    {
                      pattern: REGEX?.NAME,
                      message: 'Enter a valid Last Name',
                    },
                  ]}
                >
                  <Input placeholder="Enter Last Name" />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item name="email" label="Email Id">
              <Input disabled />
            </Form.Item>
            {/* for future use */}
            {/* <Form.Item name="defaultWorkspace" label="Default WorkSpace">
              <Select
                placeholder="Select Default WorkSpace"
                name="defaultWorkspace"
                // loading={workspaceLoading}
              >
                {workspaceData?.getUserTeams?.map((team) => (
                  <Select.Option value={team?.id} key={team?.id}>
                    {team?.teamName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item> */}
            <Divider className="card-divider" />
            <Row className="button-row">
              <Button
                type="primary"
                htmlType="submit"
                size="middle"
                loading={btnLoading}
                disabled={btnLoading}
                className="save-btn"
              >
                Save
              </Button>
            </Row>
          </Form>
        </Card>
      </div>
      <div className="card-content mt-24 account-detail-wrapper">
        <Card title="Accounts Connected" bordered className="card-title">
          <div className="social-account-block">
            {currentUser?.accountConfigs?.map((account) => (
              <div className="social-account-card" key={account?.type}>
                <div className="social-account-icon">
                  {icons[account?.type]}
                </div>
                <Tag color="success">Connected</Tag>
                <Button
                  type="primary"
                  className="save-btn"
                  onClick={() =>
                    account?.type === FIGMA
                      ? deleteFigmaMutate()
                      : deleteClickupMutate()
                  }
                >
                  Delete Account
                </Button>
              </div>
            ))}
          </div>
        </Card>
      </div>
    </div>
  );
};

export default ProfileSettings;
