import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const INVITE_MEMBER = gql`
  mutation InviteWorkspaceMember(
    $workspaceId: ID!
    $data: [InviteWorkspaceMemberInput]!
  ) {
    inviteWorkspaceMember(workspaceId: $workspaceId, data: $data) {
      message
    }
  }
`;
