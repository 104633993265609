import { UserOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Form, Input } from 'antd';
import React, { useContext, useEffect } from 'react';
import { AppContext } from '../../AppContext';
import { CURRENT_EMAIL, IS_NEW_USER, ROUTES } from '../../common/constants';
import { formValidatorRules, setItem } from '../../common/utils';
import useRouter from '../../hooks/useRouter';
import AuthWrapper from './AuthWrapper';
import { LOGIN } from './graphql/Mutations';

const { required, email } = formValidatorRules;

const Login = () => {
  const [form] = Form.useForm();
  const { navigate } = useRouter();
  const { dispatch, getToken } = useContext(AppContext);
  const idToken = getToken();

  const [loginMutate, { loading: loginLoading }] = useMutation(LOGIN, {
    onCompleted: (res) => {
      if (res?.loginUser?.message === 'OTP sent to the email!') {
        const emailValue = form.getFieldValue('email');
        dispatch({ type: 'SET_EMAIL', data: emailValue });
        setItem(CURRENT_EMAIL, emailValue);
        if (res?.loginUser?.isNewUser) {
          setItem(IS_NEW_USER, res?.loginUser?.isNewUser);
        }
        navigate(ROUTES?.VERIFY_USER);
      }
    },
    onError() {},
  });

  const onFinish = (values) => {
    const { email: userEmail } = values;
    // converted email into lowercase
    const lowerCaseEmail = userEmail?.toLowerCase();
    loginMutate({
      variables: {
        data: {
          email: lowerCaseEmail,
        },
      },
    });
  };

  useEffect(() => {
    if (idToken) {
      navigate(ROUTES?.MAIN);
    }
  }, []);

  return (
    <AuthWrapper
      title="Get Started"
      subTitle="You will receive 6 digit OTP in your inbox for verification"
      isLoading={false}
    >
      <Form
        name="Login"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        size="large"
        form={form}
      >
        <Form.Item
          name="email"
          rules={[{ required, message: 'Please enter email!' }, email]}
        >
          <Input prefix={<UserOutlined />} placeholder="Enter email" />
        </Form.Item>
        <Form.Item className=" full-width mb-8">
          <Button
            type="primary"
            className="full-width"
            htmlType="submit"
            loading={loginLoading}
          >
            Send OTP
          </Button>
        </Form.Item>
      </Form>
    </AuthWrapper>
  );
};

export default Login;
