import { gql } from '@apollo/client';

export const LOGIN = gql`
  mutation LoginUser($data: LoginUserInput!) {
    loginUser(data: $data) {
      isNewUser
      message
    }
  }
`;

export const VERIFY_OTP = gql`
  mutation VerifyOtp($data: VerifyOtpInput!) {
    verifyOtp(data: $data) {
      accessToken
      data {
        email
        firstName
        id
        isActive
        lastName
        profileImage
        role
      }
      message
      refreshToken
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser($data: UpdateUserInput!) {
    updateUser(data: $data) {
      message
    }
  }
`;

export const DELETE_FIGMA_ACCOUNT = gql`
  mutation deleteFigmaAccount {
    deleteFigmaAccount {
      message
    }
  }
`;

export const DELETE_CLICKUP_ACCOUNT = gql`
  mutation deleteClickupAccount {
    deleteClickupAccount {
      message
    }
  }
`;
