import { App } from 'antd';

let toast;
export default function ToastContainer() {
  const { message } = App?.useApp();
  toast = message;
  return null;
}

export function toastSuccess(content, duration, onClose) {
  toast?.success(content, duration, onClose);
}

export function toastInfo(content, duration, onClose) {
  toast?.info(content, duration, onClose);
}

export function toastError(content, duration, onClose) {
  toast?.error(content, duration, onClose);
}

export function toastWarning(content, duration, onClose) {
  toast?.warning(content, duration, onClose);
}

export function toastDestroy(key) {
  toast?.destroy(key);
}

// use this function to call toast with config params.
export function toastConfig(config) {
  const { type = 'info', ...rest } = config;
  toast?.[type](rest);
}
