import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import './config.less';
import CreateConfig from './createConfig';
import TeamsConfig from './teamsConfig';

const ConfigWrapper = () => (
  <>
    <Routes>
      <Route
        path={`${ROUTES?.MAIN}/:id/:team`}
        exact
        element={<TeamsConfig />}
      />
      <Route
        path={`${ROUTES?.MAIN}/:id${ROUTES?.CREATE_CONFIG}/:team`}
        exact
        element={<CreateConfig />}
      />
      {/* for future use */}
      {/* <Route
        path={`${ROUTES?.MAIN}/:id${ROUTES?.UPDATE_CONFIG}/:configId`}
        exact
        element={<CreateConfig />}
      /> */}
    </Routes>
  </>
);

export default ConfigWrapper;
