import { UserAddOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { Avatar, Button, Card } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../AppContext';
import { avatarColor } from '../../common/utils';
import LoaderComponent from '../../components/LoaderComponent';
import InviteMemberForm from './components/inviteMemberForm';
import { GET_MEMBERS_LIST } from './graphql/Queries';

const Members = () => {
  const {
    state: { workspace },
  } = useContext(AppContext);
  const [inviteModal, setInviteModal] = useState(false);

  const [
    getMembers,
    { data: membersData, loading, refetch: fetchMemberList },
  ] = useLazyQuery(GET_MEMBERS_LIST, {
    fetchPolicy: 'network-only',
    variables: {
      filters: {
        skip: 0,
        limit: 10,
      },
      workspaceId: workspace?.id,
    },
    onError() {},
  });

  useEffect(() => {
    getMembers();
  }, []);

  const onClose = () => {
    setInviteModal(false);
  };

  return (
    <div className="members-wrapper">
      <div className="card-content">
        <Card
          title={
            <div className="align-center d-flex justify-between">
              <h3>Members</h3>
              <Button
                type="primary"
                className="invite-btn"
                onClick={() => setInviteModal(true)}
              >
                <UserAddOutlined />
              </Button>
            </div>
          }
          bordered
          className="card-title"
        >
          {loading ? (
            <LoaderComponent setHeight={50} />
          ) : (
            membersData?.getWorkspaceMemberList?.map((member, i) => (
              <div className="member-block" key={`member${i + 1}`}>
                <Avatar
                  style={{
                    backgroundColor: avatarColor(member?.invitedUserEmail),
                    verticalAlign: 'middle',
                  }}
                  size="large"
                >
                  {member?.invitedUserEmail?.charAt(0)?.toUpperCase()}
                </Avatar>
                <p>{member?.invitedUserEmail}</p>
                <span className="member-role">Admin</span>
              </div>
            ))
          )}
        </Card>
      </div>
      <InviteMemberForm
        inviteModal={inviteModal}
        onClose={onClose}
        fetchMemberList={fetchMemberList}
      />
    </div>
  );
};

export default Members;
