import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Checkbox, Form, message } from 'antd';
import React, { useContext, useEffect } from 'react';
import OTPInput from 'react-otp-input';
import { NavLink } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import {
  CURRENT_EMAIL,
  IS_NEW_USER,
  OTP_SIZE,
  REDIRECT_WAITING,
  REGEX,
  ROUTES,
} from '../../common/constants';
import {
  formValidatorRules,
  getItem,
  removeItem,
  setItem,
} from '../../common/utils';
import useRouter from '../../hooks/useRouter';
import AuthWrapper from './AuthWrapper';
import { LOGIN, VERIFY_OTP } from './graphql/Mutations';
import { GET_USER_WORKSPACE } from './graphql/Queries';

const { required, number } = formValidatorRules;

const VerifyUser = () => {
  const {
    state: { userEmail },
    initializeAuth,
    getToken,
    dispatch,
  } = useContext(AppContext);
  const [form] = Form.useForm();
  const { navigate } = useRouter();
  const idToken = getToken();

  const [verifyUserMutate, { loading }] = useMutation(VERIFY_OTP, {
    onError() {},
  });

  const [getWorkspace] = useLazyQuery(GET_USER_WORKSPACE, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      dispatch({ type: 'SET_WORKSPACE', data: res?.getWorkspace?.[0] ?? null });
    },
  });

  const [resendOtp] = useMutation(LOGIN);

  useEffect(() => {
    const isEmail = getItem(CURRENT_EMAIL);
    if (idToken) {
      navigate(ROUTES?.ONBOARD);
    } else if (!isEmail) {
      navigate(ROUTES?.LOGIN);
    } else {
      setItem(REDIRECT_WAITING, true);
    }
  }, []);

  const onFinish = async (values) => {
    const { otp } = values;
    if (otp?.length === OTP_SIZE) {
      try {
        await verifyUserMutate({
          variables: {
            data: {
              email: userEmail,
              otp,
            },
          },
          onCompleted: async ({ verifyOtp }) => {
            if (verifyOtp) {
              initializeAuth(
                verifyOtp?.accessToken,
                verifyOtp?.data,
                verifyOtp?.refreshToken,
              );
              getWorkspace({
                variables: {
                  filters: {
                    limit: 10,
                    skip: 0,
                  },
                },
              });
              if (!verifyOtp?.data?.firstName || !verifyOtp?.data?.lastName) {
                navigate(ROUTES?.CREATE_USER);
              } else {
                navigate(ROUTES?.MAIN);
              }
              removeItem(REDIRECT_WAITING);
              removeItem(IS_NEW_USER);
            }
          },
        });
      } catch (error) {
        return error;
      }
    } else {
      message?.error('Please enter correct OTP');
    }
  };

  const handleEnterOtp = (otp) => {
    if (REGEX.NUMBER.test(otp) || otp === '') {
      form.setFieldsValue({ otp });
    }
  };

  const validateTerms = (_, value) => {
    if (!value) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise?.reject('Please accept terms and conditions');
    }
    return Promise?.resolve();
  };

  const resendOtpHandler = () => {
    resendOtp({
      variables: {
        data: { email: userEmail },
      },
    });
  };

  return (
    <AuthWrapper
      title="Check Your Email"
      subTitle={`Enter OTP sent to ${userEmail}`}
      isLoading={false}
    >
      <Form form={form} onFinish={onFinish} className="otp-block">
        <Form.Item
          name="otp"
          initialValue=""
          rules={[{ required, message: 'Please enter OTP' }, number]}
          className="mb-16"
        >
          <OTPInput
            value={form.getFieldValue('otp')}
            onChange={handleEnterOtp}
            numInputs={6}
            isInputNum
            renderInput={(props) => <input className="otp-input" {...props} />}
          />
        </Form.Item>
        <Form.Item
          name="agreement"
          valuePropName="checked"
          rules={[{ validator: validateTerms }]}
        >
          <Checkbox>
            I agree to the{' '}
            <NavLink to={ROUTES?.TERMS_AND_CONDITIONS} className="resend-text">
              terms and conditions{' '}
            </NavLink>
            and{' '}
            <NavLink to={ROUTES?.PRIVACY_POLICY} className="resend-text">
              privacy policy.
            </NavLink>
          </Checkbox>
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="full-width mt-24"
            loading={loading}
            disabled={loading}
          >
            Login
          </Button>
        </Form.Item>
        <p className="m-0">
          Didn't receive OTP ?{' '}
          <span onClick={resendOtpHandler} className="resend-text">
            Send Again
          </span>
        </p>
      </Form>
    </AuthWrapper>
  );
};

export default VerifyUser;
