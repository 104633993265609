import { CloseCircleOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { Result, Typography } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { Navigate, BrowserRouter as Router, useRoutes } from 'react-router-dom';
import { AppContext } from './AppContext';
import Error404 from './Error404';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import App from './app/App';
import AppLayout from './app/layout/AppLayout';
import {
  ERROR_PAGE_SUBTITLE,
  ERROR_PAGE_TITLE,
  ROUTES,
} from './common/constants';
import LoaderComponent from './components/LoaderComponent';
import MaintenancePage from './components/MaintenancePage';
import history from './historyData';
import CreateUser from './modules/auth/CreateUser';
import Login from './modules/auth/Login';
import Logout from './modules/auth/Logout';
import ProfileSettings from './modules/auth/ProfileSettings';
import RefreshToken from './modules/auth/RefreshToken';
import VerifyUser from './modules/auth/VerifyUser';
import {
  GET_CURRENT_USER,
  GET_USER_WORKSPACE,
} from './modules/auth/graphql/Queries';
import ConfigWrapper from './modules/config';
import MembersWrapper from './modules/members';
import Onboarding from './modules/onboarding';
import PrivacyPolicy from './modules/publicPages/privacyPolicy';
import TermsAndConditions from './modules/publicPages/termsAndConditions';
import TeamsWrapper from './modules/teams';

const { Paragraph } = Typography;

const MyFallbackComponent = ({ error, componentStack }) => (
  <Result
    status="error"
    title={ERROR_PAGE_TITLE}
    subTitle={ERROR_PAGE_SUBTITLE}
  >
    <div className="desc">
      <Paragraph>
        <Typography.Title level={4}> Error:</Typography.Title>
      </Paragraph>
      <Paragraph>
        <CloseCircleOutlined className="site-result-demo-error-icon" /> Your
        {error?.message?.toString()}
      </Paragraph>
      <Paragraph>
        <Typography.Title level={4}> Stacktrace:</Typography.Title>
      </Paragraph>
      <Paragraph>
        <CloseCircleOutlined className="site-result-demo-error-icon" /> Your
        {componentStack}
      </Paragraph>
    </div>
  </Result>
);

const RoutesCollection = () => {
  const DASHBOARD_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            { path: ROUTES?.MAIN, element: <Navigate to={ROUTES?.TEAMS} /> },
            { path: `${ROUTES?.TEAMS}/*`, element: <TeamsWrapper /> },
            { path: `${ROUTES?.CONFIG}/*`, element: <ConfigWrapper /> },
            { path: `${ROUTES?.MEMBERS}/*`, element: <MembersWrapper /> },
          ],
        },
      ],
    },
  ];
  const AUTH_MODULES = [
    {
      path: ROUTES?.LOGIN,
      element: <PublicRoute />,
      // Nested routes use a children property
      children: [{ path: ROUTES?.LOGIN, element: <Login /> }],
    },
    {
      path: ROUTES?.VERIFY_USER,
      element: <PublicRoute />,
      // Nested routes use a children property
      children: [{ path: ROUTES?.VERIFY_USER, element: <VerifyUser /> }],
    },
    {
      path: ROUTES?.CREATE_USER,
      element: <PrivateRoute />,
      children: [{ path: ROUTES?.CREATE_USER, element: <CreateUser /> }],
    },
    {
      path: ROUTES?.LOGOUT,
      element: <PrivateRoute />,
      children: [{ path: ROUTES?.LOGOUT, element: <Logout /> }],
    },
    {
      path: ROUTES?.AUTHENTICATION,
      element: <PrivateRoute />,
      children: [{ path: ROUTES?.AUTHENTICATION, element: <RefreshToken /> }],
    },
  ];

  const ONBOARDING_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: `${ROUTES?.ONBOARDING}/:slug`,
              element: <Onboarding />,
            },
          ],
        },
      ],
    },
  ];

  const PUBLIC_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <AppLayout />,
      children: [
        { path: ROUTES?.TERMS_AND_CONDITIONS, element: <TermsAndConditions /> },
        { path: ROUTES?.PRIVACY_POLICY, element: <PrivacyPolicy /> },
      ],
    },
  ];

  const USER_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: ROUTES?.PROFILE,
              element: <ProfileSettings />,
            },
          ],
        },
      ],
    },
  ];

  const OTHER_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: '*',
              element: <Error404 />,
            },
          ],
        },
      ],
    },
  ];

  const element = useRoutes([
    ...AUTH_MODULES,
    ...DASHBOARD_MODULES,
    ...ONBOARDING_MODULES,
    ...USER_MODULES,
    ...PUBLIC_MODULES,
    ...OTHER_MODULES,
  ]);
  return element;
};

const RoutesWrapper = () => {
  const { initializeAuth, getToken, dispatch } = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const path = history?.location?.pathname;
  const idToken = getToken();

  const [getWorkspace] = useLazyQuery(GET_USER_WORKSPACE, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      dispatch({ type: 'SET_WORKSPACE', data: res?.getWorkspace?.[0] ?? null });
    },
  });

  const [getCurrentUser] = useLazyQuery(GET_CURRENT_USER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      initializeAuth(idToken, res?.getUser);
      getWorkspace({
        variables: {
          filters: {
            limit: 10,
            skip: 0,
          },
        },
      });
      setLoading(false);
    },
    onError: () => {
      history?.push(ROUTES?.LOGOUT);
      setLoading(false);
    },
  });

  useEffect(() => {
    if (path === ROUTES?.LOGOUT || idToken) {
      getCurrentUser();
    } else {
      setLoading(false);
    }
  }, []);

  // use this variable from envs so that we can able to run maintenance page on runtime.
  const maintenance = process.env.REACT_APP_MAINTENANCE_ENABLE;

  if (loading) return <LoaderComponent />;
  return (
    <Sentry.ErrorBoundary fallback={MyFallbackComponent}>
      <Router>
        {maintenance === 'true' ? <MaintenancePage /> : <RoutesCollection />}
      </Router>
    </Sentry.ErrorBoundary>
  );
};
export default RoutesWrapper;
