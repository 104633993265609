import { Steps } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import ConnectClickup from './ConnectToClickUp';
import ConnectFigma from './ConnectToFigma';
import './onboarding.less';

const Onboarding = () => {
  const { pathname } = useLocation();
  const [current, setCurrent] = useState(0);

  const steps = [
    {
      title: 'Connect Figma',
      content: <ConnectFigma />,
      path: `${ROUTES.ONBOARDING}${ROUTES.FIGMA_CONNECT}`,
    },
    {
      title: 'Connect Clickup',
      content: <ConnectClickup />,
      path: `${ROUTES.ONBOARDING}${ROUTES.CLICK_UP_CONNECT}`,
    },
  ];

  useEffect(() => {
    const index = steps?.findIndex((step) => step?.path === pathname);
    setCurrent(index);
  }, [pathname]);

  const items = steps?.map((item) => ({
    key: item?.title,
    title: item?.title,
  }));
  return (
    <>
      <div className="onboarding-wrapper">
        <Steps current={current} items={items} />
        <div>{steps[current]?.content}</div>
      </div>
    </>
  );
};

export default Onboarding;
