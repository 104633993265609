import { gql } from '@apollo/client';

export const GET_TEAM_LIST = gql`
  query ListFigmaTeams(
    $where: ListFigmaTeamsWhereInput!
    $filter: ListFigmaTeamsFilter
  ) {
    listFigmaTeams(where: $where, filter: $filter) {
      count
      teams {
        id
        isActive
        name
        teamId
        url
      }
    }
  }
`;

export const GET_FIGMA_PROJECT_LIST = gql`
  query GetFigmaProjects($figmaProjectId: ID!) {
    getFigmaProjects(figmaProjectId: $figmaProjectId) {
      id
      name
    }
  }
`;

export const GET_WORKSPACE_CONFIG_LIST = gql`
  query GetWorkspaceConfigList($figmaProjectId: ID!, $filters: Filters!) {
    getWorkspaceConfigList(figmaProjectId: $figmaProjectId, filters: $filters) {
      id
      clickupConfig {
        folder {
          name
          id
        }
        list {
          id
          name
        }
        space {
          id
          name
        }
      }
      figmaConfig {
        name
        figmaProjectIds
        teamId
      }
    }
  }
`;

export const GET_WORKSPACE_CONFIG = gql`
  query GetWorkspaceConfig($workspaceConfigId: ID!) {
    getWorkspaceConfig(workspaceConfigId: $workspaceConfigId) {
      clickupConfig {
        folder {
          id
          name
        }
        list {
          id
          name
        }
        space {
          id
          name
        }
      }
      figmaConfig {
        figmaProjectIds
      }
      figmaProjectMembers {
        email
      }
    }
  }
`;

export const GET_CLICK_UP_SPACES = gql`
  query Spaces {
    getClickupSpaces {
      teamName
      teamId
      spaces {
        id
        name
      }
    }
  }
`;

export const GET_CLICK_UP_FOLDERS = gql`
  query GetClickupFolders($spaceId: String!) {
    getClickupFolders(spaceId: $spaceId) {
      id
      name
    }
  }
`;

export const GET_CLICK_UP_LISTS = gql`
  query GetClickupLists($folderId: String!) {
    getClickupLists(folderId: $folderId) {
      id
      name
    }
  }
`;

export const GET_CLICK_UP_MEMBERS = gql`
  query GetClickupMembers($listId: String!) {
    getClickupMembers(listId: $listId) {
      data
    }
  }
`;
