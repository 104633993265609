import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Button, Card } from 'antd';
import React, { useContext, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { ReactComponent as Figma } from '../../assets/svg/figma.svg';
import { ROUTES } from '../../common/constants';
import LoaderComponent from '../../components/LoaderComponent';
import { GET_CURRENT_USER } from '../auth/graphql/Queries';
import { ADD_FIGMA_ACCOUNT } from './graphql/Mutations';
import { GET_FIGMA_REDIRECT_URL } from './graphql/Queries';

const ConnectFigma = () => {
  const { dispatch } = useContext(AppContext);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const state = searchParams.get('state');

  const { loading, data } = useQuery(GET_FIGMA_REDIRECT_URL, {
    fetchPolicy: 'network-only',
    onError: (err) => {
      if (err?.message === 'Your account has already been added!') {
        navigate(`${ROUTES.ONBOARDING}${ROUTES.CLICK_UP_CONNECT}`);
      }
    },
  });

  const [addFigmaMutate] = useMutation(ADD_FIGMA_ACCOUNT, { onError() {} });

  const handleLoginFigma = () => {
    // eslint-disable-next-line no-undef
    window.open(data?.getFigmaRedirectionUrl?.url, '_self');
  };

  const [getUser] = useLazyQuery(GET_CURRENT_USER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      dispatch({
        type: 'SET_CURRENT_USER',
        data: res?.getUser,
      });
      if (res?.getUser?.accountConfigs?.length === 2) {
        navigate(ROUTES.MAIN);
      } else {
        navigate(`${ROUTES?.ONBOARDING}${ROUTES?.CLICK_UP_CONNECT}`);
      }
    },
  });

  useEffect(() => {
    if (code && state) {
      addFigmaMutate({
        variables: { data: { state, code } },
        onCompleted: (res) => {
          if (res?.addFigmaAccount) {
            getUser();
          }
        },
      });
    }
  }, []);
  return loading ? (
    <LoaderComponent />
  ) : (
    <>
      <Card
        title="Connect With Figma"
        bordered={false}
        className="connect-card"
      >
        <Button
          className={`figma-connect-btn ${
            !data?.getFigmaRedirectionUrl ? 'figma-connect-btn-disabled' : ''
          }`}
          onClick={handleLoginFigma}
          disabled={!data?.getFigmaRedirectionUrl}
        >
          <Figma />
          {data?.getFigmaRedirectionUrl?.url
            ? 'Connect Account'
            : 'Account Connected'}
        </Button>
      </Card>
    </>
  );
};

export default ConnectFigma;
