import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Row,
  Select,
  TreeSelect,
} from 'antd';
import { uniqBy } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { ROUTES } from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import useRouter from '../../hooks/useRouter';
import { GET_TEAM } from '../teams/graphql/Queries';
import {
  CREATE_CONFIGURATION,
  UPDATE_CONFIGURATION,
} from './graphql/Mutations';
import {
  GET_CLICK_UP_FOLDERS,
  GET_CLICK_UP_LISTS,
  GET_CLICK_UP_MEMBERS,
  GET_CLICK_UP_SPACES,
  GET_FIGMA_PROJECT_LIST,
  GET_WORKSPACE_CONFIG,
} from './graphql/Queries';

const { required } = formValidatorRules;

const CreateConfig = () => {
  const {
    state: { workspace },
  } = useContext(AppContext);
  const { configId, id: projectId, team } = useParams();
  const { navigate } = useRouter();
  const [form] = Form.useForm();

  const [projectOptions, setProjectOptions] = useState([]);
  const [spaceOptions, setSpaceOptions] = useState([]);
  const [memberOptions, setMemberOptions] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState([]);

  const [listId, setListId] = useState(null);
  const [listIdError, setListIdError] = useState('');

  const [getTeam, { data: teamData }] = useLazyQuery(GET_TEAM, {
    fetchPolicy: 'network-only',
  });

  const [getProjectList, { loading: projectLoading }] = useLazyQuery(
    GET_FIGMA_PROJECT_LIST,
    {
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  const [getSpaces, { loading: spaceLoading }] = useLazyQuery(
    GET_CLICK_UP_SPACES,
    {
      fetchPolicy: 'network-only',
    },
  );

  const [getFolders] = useLazyQuery(GET_CLICK_UP_FOLDERS, {
    fetchPolicy: 'network-only',
  });

  const [getLists] = useLazyQuery(GET_CLICK_UP_LISTS, {
    fetchPolicy: 'network-only',
  });

  const [
    getMembers,
    { data: memberFullData, loading: memberLoading },
  ] = useLazyQuery(GET_CLICK_UP_MEMBERS, {
    fetchPolicy: 'network-only',
  });

  const [
    createConfig,
    { loading: createLoading },
  ] = useMutation(CREATE_CONFIGURATION, { onError() {} });

  const [updateConfig, { loading: updateLoading }] = useMutation(
    UPDATE_CONFIGURATION,
    {
      onError() {},
    },
  );

  const [getConfiguration] = useLazyQuery(GET_WORKSPACE_CONFIG, {
    fetchPolicy: 'network-only',
  });

  const fetchProjects = () => {
    getProjectList({
      variables: {
        figmaProjectId: projectId,
      },
      onCompleted: (res) => {
        const optionArray =
          res?.getFigmaProjects?.map((item) => ({
            label: item?.name,
            value: item?.id,
          })) ?? [];
        setProjectOptions(optionArray);
      },
    });
  };

  const fetchLists = async (folderId, values) => {
    await getLists({
      variables: { folderId },
      onCompleted: (res) => {
        const spaceObj = values
          ? values?.find((item) => item?.id === folderId)
          : spaceOptions?.find((item) => item?.id === folderId);
        const optionsArray =
          res?.getClickupLists?.map((item) => ({
            title: item?.name,
            value: item?.id,
            pId: folderId,
            type: 'LIST',
            id: item?.id,
            isLeaf: true,
            team: spaceObj?.team,
            space: spaceObj?.space,
            folder: { name: spaceObj?.title, id: spaceObj?.id },
          })) ?? [];
        setSpaceOptions(
          values
            ? uniqBy(values?.concat(optionsArray), 'id')
            : uniqBy(spaceOptions?.concat(optionsArray), 'id'),
        );
      },
    });
  };
  const fetchFolders = async (spaceId, values, list) => {
    await getFolders({
      variables: { spaceId },
      onCompleted: (res) => {
        const spaceObj = values
          ? values?.find((item) => item?.id === spaceId)
          : spaceOptions?.find((item) => item?.id === spaceId);
        const optionsArray =
          res?.getClickupFolders?.map((item) => ({
            title: item?.name,
            value: item?.id,
            pId: spaceId,
            type: 'FOLDER',
            id: item?.id,
            selectable: false,
            space: {
              name: spaceObj?.title,
              id: spaceObj?.id,
            },
            team: spaceObj?.team,
          })) ?? [];
        setSpaceOptions(
          values
            ? uniqBy(values?.concat(optionsArray), 'id')
            : uniqBy(spaceOptions?.concat(optionsArray), 'id'),
        );
        if (list) {
          fetchLists(list, uniqBy(values?.concat(optionsArray), 'id'));
        }
      },
    });
  };

  const fetchSpaces = async (spaceId, list) => {
    await getSpaces({
      onCompleted: (res) => {
        const tempArray = [];
        res?.getClickupSpaces?.map((item) => {
          tempArray?.push({
            title: item?.teamName,
            value: item?.teamId,
            id: item?.teamId,
            type: 'ORG',
            selectable: false,
            isLeaf: !item?.spaces?.length > 0,
          });
          const arrayWrong = item?.spaces?.map((space) =>
            tempArray?.push({
              title: space?.name,
              value: space?.id,
              pId: item?.teamId,
              id: space?.id,
              type: 'SPACE',
              selectable: false,
              team: { id: item?.teamId, name: item?.teamName },
            }),
          );
          return arrayWrong;
        });
        setSpaceOptions(tempArray);
        if (spaceId && list) {
          const selectedSpace = tempArray?.find((item) => item?.id === spaceId);
          setExpandedKeys([selectedSpace?.pId, spaceId, list]);
          fetchFolders(spaceId, tempArray, list);
        }
      },
    });
  };

  const fetchConfiguration = async () => {
    await getConfiguration({
      variables: { workspaceConfigId: configId },
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        const dataToSet = {
          figmaProjects: res?.getWorkspaceConfig?.figmaConfig?.figmaProjectIds,
          members: res?.getWorkspaceConfig?.figmaProjectMembers?.map(
            (item) => item?.email,
          ),
        };
        setListId(res?.getWorkspaceConfig?.clickupConfig?.list?.id);
        fetchSpaces(
          res?.getWorkspaceConfig?.clickupConfig?.space?.id,
          res?.getWorkspaceConfig?.clickupConfig?.folder?.id,
        );
        form.setFieldsValue({ ...dataToSet });
      },
    });
  };

  const fetchMembers = async (listKey) => {
    await getMembers({
      variables: { listId: listKey },
      onCompleted: (res) => {
        const optionsArray =
          res?.getClickupMembers?.data?.members?.map((item) => ({
            label: item?.username,
            value: item?.email,
          })) ?? [];
        setMemberOptions(optionsArray);
      },
    });
  };

  useEffect(() => {
    fetchProjects();
    getTeam({
      variables: {
        where: {
          id: projectId,
        },
      },
    });
    if (configId) {
      fetchConfiguration();
    } else {
      fetchSpaces();
    }
  }, []);

  useEffect(() => {
    if (listId) {
      fetchMembers(listId);
    }
  }, [listId]);

  const checkValidate = () => {
    if (listId) {
      setListIdError('');
      return true;
    }
    setListIdError('Select figma list');
    return false;
  };

  const onFinish = (values) => {
    if (checkValidate()) {
      const memberArray = values?.members?.map((item) => {
        const memberDetail = memberFullData?.getClickupMembers?.data?.members?.find(
          (ele) => ele?.email === item,
        );

        const returnObj = {
          email: memberDetail?.email,
          memberId: JSON.stringify(memberDetail?.id),
          userName: memberDetail?.username,
          metaData: memberDetail,
        };
        return returnObj;
      });

      const selectedList = spaceOptions?.find((item) => item?.id === listId);

      const clickupConfigToPass = {
        team: selectedList?.team,
        space: selectedList?.space,
        folder: selectedList?.folder,
        list: { name: selectedList?.title, id: selectedList?.id },
      };

      const dataToPass = {
        figmaProjectMembers: memberArray,
        figmaConfig: {
          name: teamData?.getFigmaTeam?.name,
          teamId: teamData?.getFigmaTeam?.teamId,
          figmaProjectIds: values?.figmaProjects,
        },
        clickupListId: selectedList?.id,
        clickupConfig: clickupConfigToPass,
      };

      if (configId) {
        updateConfig({
          variables: {
            input: {
              ...dataToPass,
              workspaceConfigId: configId,
            },
          },
          onCompleted: () => navigate(`${ROUTES?.CONFIG}/${projectId}/${team}`),
        });
      } else {
        createConfig({
          variables: {
            input: {
              ...dataToPass,
              figmaProjectId: projectId,
              workspaceId: workspace?.id,
            },
          },
          onCompleted: () => navigate(`${ROUTES?.CONFIG}/${projectId}/${team}`),
        });
      }
    }
  };

  const onLoadData = (data) =>
    new Promise((resolve) => {
      setTimeout(() => {
        if (data?.type === 'SPACE') {
          fetchFolders(data?.id);
        } else if (data?.type === 'FOLDER') {
          fetchLists(data?.id);
        }
        resolve(undefined);
      }, 300);
    });
  const onChange = (newValue) => {
    setListId(newValue);
    form.setFieldValue('members', []);
    setListIdError('');
  };

  const handleSelectAll = () => {
    const value = form.getFieldValue('figmaProjects');
    if (value?.length === projectOptions?.length) {
      form.setFieldsValue({ figmaProjects: [] });
    } else {
      const allOptions = projectOptions?.map((item) => item?.value);
      form.setFieldsValue({ figmaProjects: allOptions });
    }
  };

  return (
    <>
      <div className="profile-card">
        <div className="card-content">
          <Card
            title={
              configId
                ? `Edit Configuration ( ${team} )`
                : `Add Configuration ( ${team} )`
            }
            bordered
            className="card-title"
          >
            <Form
              name="config-form"
              layout="vertical"
              form={form}
              onFinish={onFinish}
              onFinishFailed={checkValidate}
            >
              <Row gutter={10}>
                <Col span={24}>
                  <Form.Item
                    name="figmaProjects"
                    label="Figma Projects"
                    rules={[
                      {
                        ...required,
                        message: 'Select figma project',
                      },
                    ]}
                  >
                    <Select
                      mode="multiple"
                      placeholder="Select figma project"
                      loading={projectLoading}
                      dropdownRender={(menu) => (
                        <div>
                          <div className="figma-projects-render">
                            <div>
                              <span onClick={handleSelectAll}>Select All</span>
                            </div>
                          </div>
                          {menu}
                        </div>
                      )}
                    >
                      {projectOptions?.map((project) => (
                        <Select.Option key={project?.value}>
                          {project?.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24} className="mb-24">
                  <span className="figma-list-label">Clickup List</span>
                  <TreeSelect
                    loading={spaceLoading}
                    treeDataSimpleMode
                    className={`mt-8 clickup-list-select ${
                      listIdError ? 'figma-list-error-class' : ''
                    }`}
                    value={listId}
                    dropdownStyle={{
                      maxHeight: 400,
                      overflow: 'auto',
                    }}
                    placeholder="Please select"
                    onSelect={onChange}
                    loadData={onLoadData}
                    treeData={spaceOptions}
                    treeExpandedKeys={expandedKeys}
                    onTreeExpand={(values) => setExpandedKeys(values)}
                  />
                  {listIdError && (
                    <div className="figma-list-error">{listIdError}</div>
                  )}
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="members"
                    label="Clickup Members"
                    rules={[
                      {
                        ...required,
                        message: 'Select click up member',
                      },
                    ]}
                  >
                    <Select
                      mode="multiple"
                      placeholder="Select click up member"
                      options={memberOptions}
                      loading={memberLoading}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Divider className="card-divider" />
              <Row className="button-row">
                <Button
                  type="primary"
                  htmlType="submit"
                  size="middle"
                  loading={createLoading || updateLoading}
                  disabled={createLoading || updateLoading}
                  className="save-btn"
                >
                  Save
                </Button>
                <Button
                  size="middle"
                  className="save-btn ml-16"
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </Button>
              </Row>
            </Form>
          </Card>
        </div>
      </div>
    </>
  );
};

export default CreateConfig;
