import { Divider, Layout } from 'antd';
import React, { useMemo } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import logo from '../../../assets/images/logo.png';
import { ROUTES } from '../../../common/constants';
import UserProfile from './UserProfile';
import './app-header.less';

const { Header } = Layout;

const AppHeader = ({ isUser }) => {
  const { pathname } = useLocation();

  const isOnboarding = useMemo(() => pathname?.includes('onboarding'), [
    pathname,
  ]);

  return (
    <Header className="site-layout-sub-header-background app-header">
      <div className="header-wrapper">
        <div className="header-left">
          <NavLink
            to={ROUTES?.MAIN}
            className="d-flex align-center logo-wrapper"
          >
            <img src={logo} alt="logo" height={46} />
          </NavLink>
          {isUser && !isOnboarding && (
            <>
              <Divider type="vertical" className="app-header-divider" />
              <div className="header-left-content">
                <ul className="header-left-menu">
                  <li>
                    <NavLink to={ROUTES?.TEAMS}>Teams</NavLink>
                  </li>
                  <li>
                    <NavLink to={ROUTES?.MEMBERS}>Members</NavLink>
                  </li>
                </ul>
              </div>
            </>
          )}
        </div>
        {isUser && (
          <div className="header-right">
            {!isOnboarding && (
              <div className="header-notification">
                <UserProfile />
              </div>
            )}
          </div>
        )}
      </div>
    </Header>
  );
};
export default AppHeader;
