import { gql } from '@apollo/client';

export const LIST_TEAMS = gql`
  query ListFigmaTeams(
    $where: ListFigmaTeamsWhereInput!
    $filter: ListFigmaTeamsFilter
  ) {
    listFigmaTeams(where: $where, filter: $filter) {
      count
      teams {
        id
        isActive
        name
        teamId
        url
      }
    }
  }
`;

export const GET_TEAM = gql`
  query GetFigmaTeam($where: FigmaProjectWhereUniqueInput!) {
    getFigmaTeam(where: $where) {
      url
      name
      teamId
    }
  }
`;
