import { InfoCircleOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Card, Col, Divider, Form, Input, Modal, Row } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { REGEX, ROUTES } from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import useRouter from '../../hooks/useRouter';
import { CREATE_TEAM, UPDATE_TEAM } from './graphql/Mutations';
import { GET_TEAM } from './graphql/Queries';

const { required } = formValidatorRules;

const CreateTeam = () => {
  const {
    state: { workspace },
  } = useContext(AppContext);
  const { id } = useParams();
  const { navigate } = useRouter();
  const [form] = Form.useForm();

  const [infoModal, setInfoModal] = useState(false);

  const [createTeam, { loading: createLoading }] = useMutation(CREATE_TEAM, {
    onError() {},
  });

  const [updateTeam, { loading: updateLoading }] = useMutation(UPDATE_TEAM, {
    onError() {},
  });

  const [getTeam] = useLazyQuery(GET_TEAM, { onError() {} });

  useEffect(() => {
    if (id) {
      getTeam({
        variables: { where: { id } },
        fetchPolicy: 'network-only',
        onCompleted: (res) => form.setFieldsValue({ ...res?.getFigmaTeam }),
      });
    }
  }, []);

  const onFinish = async (values) => {
    if (id) {
      updateTeam({
        variables: {
          where: { id },
          data: { ...values },
        },
        onCompleted: () => navigate(ROUTES?.TEAMS),
      });
    } else {
      createTeam({
        variables: {
          data: {
            ...values,
            workspaceId: workspace?.id,
          },
        },
        onCompleted: () => navigate(ROUTES?.TEAMS),
      });
    }
  };

  const handleInfoModalClose = () => {
    setInfoModal(false);
  };
  return (
    <>
      <div className="profile-card">
        <div className="card-content">
          <Card
            title={id ? 'Edit Team' : 'Add Team'}
            bordered
            className="card-title"
          >
            <Form
              name="profile-settings"
              layout="vertical"
              form={form}
              onFinish={onFinish}
            >
              <Row gutter={10}>
                <Col span={24}>
                  <Form.Item
                    name="name"
                    label="Name"
                    rules={[
                      {
                        ...required,
                        whitespace: true,
                        message: 'Enter a valid Name',
                      },
                      {
                        pattern: REGEX?.NAME,
                        message: 'Enter a valid Name',
                      },
                    ]}
                  >
                    <Input placeholder="Enter Name" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="url"
                    label={
                      <p className="d-flex align-center">
                        Url
                        <InfoCircleOutlined
                          className="info-icon"
                          onClick={() => setInfoModal(true)}
                        />
                      </p>
                    }
                    rules={[
                      {
                        ...required,
                        message: 'Enter a valid url',
                      },
                    ]}
                  >
                    <Input placeholder="Enter Url" />
                  </Form.Item>
                </Col>
              </Row>
              <Divider className="card-divider" />
              <Row className="button-row">
                <Button
                  type="primary"
                  htmlType="submit"
                  size="middle"
                  loading={createLoading || updateLoading}
                  disabled={createLoading || updateLoading}
                  className="save-btn"
                >
                  Save
                </Button>
                <Button
                  size="middle"
                  className="save-btn ml-16"
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </Button>
              </Row>
            </Form>
          </Card>
          <Modal open={infoModal} footer={null} onCancel={handleInfoModalClose}>
            <h3>To obtain the Team URL:</h3>
            <p className="m-0">
              1. Navigate to Figma and access the list of all teams.
            </p>
            <p className="m-0">
              2. Select the specific team you want to obtain the URL for.
            </p>
            <p className="m-0">
              3. Once inside the chosen team, copy the URL from the browser
              address bar.
            </p>
            <h3>The URL format will resemble the following:</h3>
            <p>
              https://www.figma.com/files/1064895119320101234/team/2411237935385115679/Team?fuid=137672354665313201
            </p>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default CreateTeam;
