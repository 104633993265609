import { Card, Spin } from 'antd';
import React from 'react';
import logo from '../../assets/images/logo.png';
import './auth.less';

const AuthWrapper = ({ title, subTitle, isLoading, children }) => (
  <>
    <div className="auth-bg">
      <div className="login-wrap d-flex align-center justify-start">
        <Card className="full-width">
          <Spin spinning={isLoading} wrapperClassName="full-width">
            <div className="logo-wrapper">
              <img src={logo} alt="logo" />
            </div>
            <div>
              <h2 className="login-title">{title}</h2>
              <h4 className="login-subtitle">{subTitle}</h4>
            </div>
            {children}
          </Spin>
        </Card>
        <div className="text-center login-copyrights">
          Logicwind © {new Date().getFullYear()}
        </div>
      </div>
    </div>
  </>
);

export default AuthWrapper;
