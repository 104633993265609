import { gql } from '@apollo/client';

export const CREATE_TEAM = gql`
  mutation Mutation($data: CreateFigmaTeamInput!) {
    createFigmaTeam(data: $data) {
      message
    }
  }
`;

export const UPDATE_TEAM = gql`
  mutation UpdateFigmaTeam(
    $data: UpdateFigmaTeamInput!
    $where: FigmaProjectWhereUniqueInput!
  ) {
    updateFigmaTeam(data: $data, where: $where) {
      message
    }
  }
`;

export const DELETE_TEAM = gql`
  mutation DeleteFigmaTeam($where: FigmaProjectWhereUniqueInput!) {
    deleteFigmaTeam(where: $where) {
      message
    }
  }
`;
